import React from "react";
import { Row, Col } from "react-bootstrap";
import Logo from "../../../images/partners/bioxag.png";

const bizagi = () => {
  return (
    <div className="partner-detail-card">
      <Row>
        <div className="top-section">
          <div>
            <img className="img-fluid" src={Logo} alt="PartnerImage" />
          </div>
          <div className="padding-add">
            <h3>Bizagi</h3>
            <div className="lbl-ptnrn">Service Providers</div>
            <div>
              <a href="#">Visit Website</a>{" "}
              <span>
                
              </span>
            </div>
          </div>
        </div>
      </Row>
      <Row>
        <Col>
          <div className="mt-3">
            <h4>About Bizagi</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eu
              pulvinar diam. Nullam nisl erat, tempus id erat in, egestas
              lobortis sapien. Suspendisse facilisis placerat metus sit amet
              congue. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Fusce eu pulvinar diam. Nullam nisl erat, tempus id erat in,
              egestas lobortis sapien. Suspendisse facilisis placerat metus sit
              amet congue. Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. Fusce eu pulvinar diam. Nullam nisl erat, tempus id erat in,
              egestas lobortis sapien. Suspendisse facilisis placerat metus sit
              amet congue.
            </p>
          </div>
          <div className="mt-3">
            <h4>Focus Areas</h4>
            <div className="p"><span className="vdivide">-{" "}</span>Cloud Services</div>
            <div className="p"><span className="vdivide">-{" "}</span>IOT Machine</div>
            <div className="p"><span className="vdivide">-{" "}</span>Machine Learning</div>
          </div>
          <div className="mt-3">
            <h4>Operating Countries</h4>
            <p>Country 1 <span className="vdivide">|</span> Country 2 <span className="vdivide">|</span> Country 3 <span className="vdivide">|</span> Country 4</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default bizagi;
